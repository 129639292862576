import { render, staticRenderFns } from "./BetaYoutubeCard.vue?vue&type=template&id=27c6a2bd&scoped=true"
import script from "./BetaYoutubeCard.vue?vue&type=script&lang=js"
export * from "./BetaYoutubeCard.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "27c6a2bd",
  null
  
)

export default component.exports